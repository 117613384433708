import { convertDenomToMicroDenom, hasTaxToken } from '../../Helpers/utils';
import { classicNetwork } from '../../consts/networks';
import { getAddress, walletState } from '../../state/wallet-state';
import { MsgExecuteContract } from 'cosmes/client';
import {
  CosmosBaseV1beta1Coin as Coin,
} from 'cosmes/protobufs';
import { loadTaxInfo } from '../api';
import { Asset } from 'types/pairs';

export const createPairMsg = ({firstToken, secondToken}: {firstToken: Asset, secondToken: Asset}) => {
  const address = walletState.get.address()!;
  const asset1 = firstToken?.contract_addr?.startsWith('terra')
    ? {
      token: {
        contract_addr: firstToken.contract_addr,
      },
    } : {
      native_token: {
        denom: firstToken.contract_addr,
      },
    };
  const asset2 = secondToken?.contract_addr?.startsWith('terra')
    ? {
      token: {
        contract_addr: secondToken.contract_addr,
      },
    } : {
      native_token: {
        denom: secondToken.contract_addr,
      },
    };

  return new MsgExecuteContract({
    sender: address,
    contract: classicNetwork.factoryContract,
    msg: {
      create_pair: {
        asset_infos: [asset1, asset2],
      }
    },
    funds: []
  });
};

export const swapMsg = async ({ 
  fromAmount,
  fromAsset,
  toAsset,
  swap_operations
  // slippage
}: {
    fromAmount: string;
    fromAsset: Asset;
    toAsset: Asset;
    swap_operations: {}
    // slippage: number;
}) => {
  const address = getAddress();
  //tax
  const fromValue = convertDenomToMicroDenom(fromAmount, fromAsset.decimals);
  const funds = [];
  if (!fromAsset?.contract_addr?.startsWith('terra')) {
    funds.push({
      denom: fromAsset.contract_addr,
      amount: fromValue,
    });
  }

  const msg = {
    execute_swap_operations: swap_operations
  };
  if (fromAsset?.contract_addr?.startsWith('terra')) {
    return new MsgExecuteContract({
      msg: {
        send: {
          contract: classicNetwork.routerContract,
          amount: fromValue,
          msg: btoa(JSON.stringify(msg))
        }
      },
      sender: address,
      contract: fromAsset.contract_addr,
      funds
    });
  }
  return new MsgExecuteContract({
    sender: address,
    contract: classicNetwork.routerContract,
    funds,
    msg
  });
};

export const calcSwapTax = async (fromAmount: string, fromAsset: Asset) => {
  const tax: Coin[] = [];
  const taxRate = walletState.get.taxRate();
  const taxCap = await loadTaxInfo(fromAsset.contract_addr);
  if (taxCap && hasTaxToken(fromAsset.contract_addr)) {
    tax.push(new Coin({ denom: fromAsset.contract_addr, amount: `${Math.min(Number(taxCap), Number(convertDenomToMicroDenom(fromAmount)) * Number(taxRate))}`}));
  }
  return tax;
};

export const swapTreasuryMsg = ({treasuryContract}: {treasuryContract: string}) => {
  const address = walletState.get.address()!;
  return new MsgExecuteContract({
    sender: address,
    contract: treasuryContract,
    msg: {
      swap_all: {},
    },
    funds: []
  });
};

export const distributeTreasuryMsg = ({treasuryContract}: {treasuryContract: string}) => {
  const address = walletState.get.address()!;
  return new MsgExecuteContract({
    sender: address,
    contract: treasuryContract,
    msg: {
      distribute: {},
    },
    funds: [],
  });
};
