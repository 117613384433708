import { RpcClient, getCw20Balance, getNativeBalances } from 'cosmes/client';
import { useAddress } from 'state/wallet-state';
import { find, forEach } from 'lodash';
import { convertMicroDenomToDenom } from 'Helpers/utils';
import { useQuery } from '@tanstack/react-query';
import { DEFAULT_RPC_ENDPOINT, TERRA_TOKEN_ADDRESS } from 'consts/misc';
import { Asset } from 'types/pairs';
import { utf8 } from 'cosmes/codec';
import { CosmwasmWasmV1QuerySmartContractStateService } from 'cosmes/protobufs';

export const useBalance = (token: Asset | null) => {
  const address = useAddress();

  const { data } = useQuery({
    enabled: !!(address && token?.contract_addr),
    queryFn: async () => {
      if (token && token.type === 'cw20') {
        const amount = await getCw20Balance(DEFAULT_RPC_ENDPOINT, {
          address: address!, //checked by the enable flag
          token: token.contract_addr,
        });
        return amount.toString();
      }
      const coins = await getNativeBalances(DEFAULT_RPC_ENDPOINT, {
        address: address!,
      });
      return find(coins, ({denom}) => denom === token?.microdenom)?.amount;
    },
    queryKey: ['balance', token?.contract_addr],
  });
  return data ? convertMicroDenomToDenom(data, token?.decimals) : 0;
};

export const useCW20Balance = (contract_addr: string | null) => {
  const address = useAddress();

  const { data } = useQuery({
    enabled: !!(address && contract_addr),
    queryFn: async () => {
      if (contract_addr) {
        const amount = await getCw20Balance(DEFAULT_RPC_ENDPOINT, {
          address: address!, //checked by the enable flag
          token: contract_addr,
        });
        return amount.toString();
      }
    },
    queryKey: ['balance', contract_addr],
  });
  return data ? convertMicroDenomToDenom(data) : 0;
};

export const useTerraBalance = () => {
  return useCW20Balance(TERRA_TOKEN_ADDRESS);
};

export const useBalances = (tokens: Asset[]) => {
  const address = useAddress();
  return useQuery(
    {
      enabled: !!address && tokens.length > 0,
      queryKey: ['balance', ...tokens.map(t => t.contract_addr)],
      queryFn: async () => {
        const batch = RpcClient.newBatchQuery(DEFAULT_RPC_ENDPOINT);
        const responses: Record<string, number> = {};
        forEach(tokens, (t): void => {
          batch.add(
            CosmwasmWasmV1QuerySmartContractStateService,
            {
              address: t.contract_addr,
              queryData: utf8.decode(JSON.stringify({ balance: { address: address ?? '' }  }))
            },
            (error, response) => {
              responses[t.contract_addr] = Number(JSON.parse(atob(response?.toJson().data ?? '')).balance);
            }
          );
        });
        await batch.send();
        return responses; 
      }
    }
  );
};
