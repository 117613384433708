import { FaX, FaXTwitter } from 'react-icons/fa6';
import { Link, useLocation } from 'react-router-dom';
import { FC, useRef, useState } from 'react';
import clsx from 'clsx/lite';
import { MultiNavItem } from 'types/routes';
import { FaTelegramPlane, FaMediumM } from 'react-icons/fa';
import { LinkButton } from 'ui/components/button';
import { t } from 'i18next';
import { map } from 'lodash';
import { useAppRoutes } from 'hooks/use-routes';

export const MobileNavMenu: FC = () => {
  const {routes, currentIndex} = useAppRoutes();
  const {pathname} = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  
  const [showPanel, setShowPanel] = useState(false);

  const filteredRoutes =
    routes.filter((route) => !route.hidden?.()).map((route) => 
      route.multipath ? (route as MultiNavItem).paths.filter((route) => !route.hidden?.()) : route
    );
  return (
    <footer className="sticky bottom-0 h-fit lg:hidden w-dvw shadow-2xl shadow-background4 z-[2]" style={{ boxShadow: '0px 0px 32px 11px rgb(10 20 35)'}} ref={ref}>
      {showPanel && filteredRoutes[currentIndex] && filteredRoutes[currentIndex].constructor === Array &&
      <>
        <div className="fixed inset-0 backdrop-blur-xl" onClick={() => setShowPanel(false)} />
        <div className='p-4 flex flex-col gap-4 bg-background4 relative z-[2]'>
          {map(filteredRoutes[currentIndex],
            (route) => 
            {
              return (
                <Link
                  onClick={() => setShowPanel(false)}
                  key={route.title}
                  to={route.pathname}
                  className={clsx(
                    'no-underline',
                    pathname.includes(route.pathname) ? 'text-accent-btn' : 'text-white'
                  )}
                >
                  {route.title}
                </Link>
              );
            }
          )
          }
          <FaX className='text-white absolute top-4 right-4' onClick={() => setShowPanel(false)}/>
        </div>
      </>
      }
      <nav className="relative bg-background2 px-4 p-2 gap-2 flex flex-row justify-evenly items-center z-[2]">
        {routes.map((route, index) =>
          <Link
            key={route.title}
            className={clsx(
              'flex flex-col gap-1 items-center justify-center no-underline text-xs font-bold cursor-pointer opacity-70 uppercase',
              currentIndex === index ? 'text-accent-btn fill-theme-yellow1' : '!text-white fill-white'
            )}
            to={route.multipath 
              ? route.paths[0].pathname 
              : route.pathname}
            onClick={() => setShowPanel(true)}
          >
            {route.icon && <route.icon width='30px' height='30px' className='size-5'/>}
            {(route.mobileTitle ?? route.title)}
          </Link>
        )}
      </nav>
    </footer>
  );
};

export const Footer: FC = () => {
  return (
    <>
      <div className='flex w-full bg-background1 flex-col md:flex-row gap-2 md:gap-10 items-center justify-center center mt-auto p-2 z-[1]'>
        <div className='flex flex-row gap-2'>
          <LinkButton className='!px-2 !py-1 center' to="https://t.me/TerracVitaCommunity" target='_blank'>
            <FaTelegramPlane className="size-4" />
          </LinkButton>
          <LinkButton className='!px-2 !py-1 center' to="https://medium.com/@terraport" target='_blank'>
            <FaMediumM className="size-4" />
          </LinkButton>
          <LinkButton className='!px-2 !py-1 center' to="https://twitter.com/_terraport_" target='_blank'>
            <FaXTwitter className="size-4" />
          </LinkButton>
        </div>
        <div className='w-[1px] bg-slate-300 h-full' />
        <div className='flex flex-row gap-6 text-white text-sm'>
          <Link className='text-white no-underline' to='/privacyPolicy'>{t('routes.privacy_policy')}</Link>
          <Link className='text-white no-underline' to='/privacyPolicy'>{t('routes.risk_advice')}</Link>
          <Link className='text-white no-underline' to='/'>{t('routes.powered')}</Link>
        </div>
      </div>
    </>
  );
};