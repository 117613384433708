import clsx from 'clsx/lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

export default function Pending({ small }: {small?: boolean}) {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      toast.info(<p>We apologize for the inconvenience, an error has occurred and the page will be reloaded.</p>);
      setTimeout(() => location.reload(), 3000);
    }, 60000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <>
      <div className={clsx('loader-wrapper', small && 'relative')}>
        <div className="lds-circle">
          <img src="/assets/gif/loader.gif" alt="" className="loader" />
        </div>
      </div>
    </>
  );
}
