import { ComponentProps, FC, Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { Transition, Dialog, TransitionChild, DialogPanel, DialogTitle, DialogBackdrop } from '@headlessui/react';
import { Card } from './card';

// export const Modal: FC<{open: boolean; onClose: () => void, children: ReactNode}> = ({open, onClose, children}) => {

//   return (
//     <Transition appear show={open} as={Fragment}>
//       <Dialog open={open} onClose={onClose}>
//         <Dialog.Panel>
//           <Dialog.Title>Deactivate account</Dialog.Title>
//           <Dialog.Description>
//           This will permanently deactivate your account
//           </Dialog.Description>
//           {children}
//           <p>
//           Are you sure you want to deactivate your account? All of your data
//           will be permanently removed. This action cannot be undone.
//           </p>

//           <button onClick={onClose}>Deactivate</button>
//           <button onClick={onClose}>Cancel</button>
//         </Dialog.Panel>
//       </Dialog>
//     </Transition>
//   );
// };

export const useModal = () => {
  const [open, setOpen] = useState(false);
  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => setOpen(false), []);
  return useMemo(() => ({open, openModal, closeModal}), [open, openModal, closeModal]);
};

export const CardModal: FC<ComponentProps<typeof Card> & { open: boolean; onClose: () => void;}> = ({
  open = false,
  onClose,
  ...cardProps
}) => {

  return (
    <Dialog open={open} as="div" className="relative z-10" onClose={onClose} transition >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/30 backdrop-blur-md duration-300 ease-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-dvh items-center justify-center text-center">
          <DialogPanel
            transition
            className="transform shadow-xl transition-all data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <Card {...cardProps} />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};