import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import axios from 'axios';
import { baseUrl } from './base';
import { keyBy } from 'lodash';
import { walletState } from 'state/wallet-state';
import { Asset } from 'types/pairs';
import { toast } from 'react-toastify';

export type Tokens = Record<string, Asset>;

export const useGetTokensQuery = (params?: { creator?: string, verified?: boolean, key?: string  }) => {
  return useSuspenseQuery<Tokens>(
    {
      queryKey: ['tokens', params?.creator, params?.verified],
      // enabled: !!assets,
      refetchInterval: 60000,
      queryFn: async () => {
        const resp = await axios.get<Asset[]>(`${baseUrl}/tokens`, { params: {
          creator_address: params?.creator, verified: params?.verified
        }});
        return keyBy(resp.data, params?.key ?? 'microdenom');
      }
    }
  );
};

export const useGetDevTokensQuery = () => {
  const address = walletState.use.address();
  return useQuery<Asset[]>(
    {
      enabled: !!address,
      queryKey: ['tokens', address],
      // enabled: !!assets,
      refetchInterval: 60000,
      queryFn: async () => {
        const resp = await axios.get<Asset[]>(`${baseUrl}/development/tokens`, {
          headers:{
            Authorization: `Bearer ${walletState.get.jwt()}`
          }
        });
        return resp.data;
      }
    }
  );
};

export type CreatedTokenParams = {
  contract_addr: string;
  name: string;
  denom:string;
  creator_address:string;
  img?:string;
  metadata?:object;
  microdenom?:string;
  decimals?:number;
  cap?: string;
}

export const announceCreatedToken = async (body: CreatedTokenParams) => {
  const res = await axios.post<CreatedTokenParams>(`${baseUrl}/tokens`,
    body,
    {
      headers:{
        Authorization: `Bearer ${walletState.get.jwt()}`
      }
    },
  );
  return res.data;
};

type ImportTokenParams = {
  contract_addr: string;
};
export const importToken = async (body: ImportTokenParams) => {
  const res = await axios.post<ImportTokenParams>(`${baseUrl}/development/tokens/import`,
    body,
    {
      headers:{
        Authorization: `Bearer ${walletState.get.jwt()}`
      }
    },
  );
  return res.data;
};

export type TokenValueQuery = {
  amount: number;
  value: number;
};

export const useGetTokenValueQuery = async (contract_addr?: string) => {
  return useQuery<TokenValueQuery>(
    {
      enabled: !!contract_addr,
      queryKey: [contract_addr, 'value'],
      refetchInterval: 60000,
      queryFn: async () => {
        const resp = await axios.get(`${baseUrl}/tokens/${contract_addr}/value`);
        return resp.data;
      }
    }
  );
};

export const deleteToken = async (address: string) => {
  const toastId = toast.loading('Deleting..');
  const res = await axios.delete(`${baseUrl}/development/tokens/${address}`,
    {
      headers:{
        Authorization: `Bearer ${walletState.get.jwt()}`
      }
    },
  );
  toast.dismiss(toastId);
  if(res.status === 200){
    toast.success('Token Removed!');
  }
  return res.data;
};
