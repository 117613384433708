import clsx from 'clsx/lite';
import { CSSProperties, FC, HTMLAttributes, ReactNode, createContext, forwardRef, useContext, useEffect, useMemo } from 'react';
import { createStore } from 'zustand-x';
import { IconToggle } from './button';
import { BsChevronDown, BsStarFill } from 'react-icons/bs';
import { keyBy, map } from 'lodash';
import { Transition } from '@headlessui/react';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { useTailwindLG, useTailwindMD, useTailwindSM } from 'Helpers/responsive';

type Column = {
  key: string;
  sortable?: boolean;
  label: ReactNode;
  justify?: 'center' | 'start' | 'end';
  width?: string; // grid column width definition;
  mobile?: boolean;
  tablet?: boolean;
}

type TableContext = {
  limit: number;
  config: {
    enableGridSwitching: boolean;
    enableStarring: boolean;
    enableTrending: boolean;
  }
  gridMode: boolean;
  columns: Column[];
  starred: Record<string, boolean>
  filters: {
    starred: boolean;
    sortBy: string | null;
    sortAsc: boolean;
    [key: string]: unknown;
  };
};

export const createTableStore = (name: string, {filters, ...initial}: Partial<TableContext>) => createStore(name)<TableContext>({
  config: {
    enableGridSwitching: true,
    enableStarring: true,
    enableTrending:false,
  },
  gridMode: false,
  starred: {},
  columns: [],
  filters: {
    starred: false,
    trending: false,
    sortBy: null,
    sortAsc: true,
    ...filters
  },
  limit: 10,
  ...initial
}, {
  persist: {
    version: 5,
    migrate: ({starred}) => ({ starred }),
    enabled: import.meta.env.PROD,
    partialize: ({starred}) => ({starred}),
    name: `table-${name}`
  }
}).extendActions((set, get) => ({
  filter: (name: string, value: unknown) => {
    set.filters({ ...get.filters(), [name]: value });
  },
  toggleStar: (key: string) => {
    const starred = get.starred();
    set.starred({ ...starred, [key]: !starred[key] });
  }
})
).extendSelectors((state) => ({
  filter: (name: string) => state.filters[name],
  columnsMap: () => keyBy(state.columns, c => c.key)
}));

type GridStore = ReturnType<typeof createTableStore>;

const storeContext = createContext<GridStore | null>(null);

export const useGridStore = () => {
  const store = useContext(storeContext);
  useEffect(() => {
    if (store === null) {
      console.warn('Must be used inside a grid!');
    }
  }, [store]);
  return store!;
};

type DataGridProps = {
  filterBar?: ReactNode;
  store: GridStore;
  children: ReactNode;
  headers?: ReactNode;
  quickFilters?: ReactNode;
  innerClassName?: string;
}

export const HeaderCell: FC<{column: Column; children?: ReactNode; className?: string }> = ({ column, children, className }) => {
  const store = useGridStore();
  const filters = store.use.filters();
  return (
    <div
      className={clsx(
        column.mobile ? 'flex' : 'hidden lg:flex',
        column.tablet ? 'md:flex' : '',
        'p-2 font-bold flex-row flex gap-1 sticky top-0',
        column.justify === 'center' && 'justify-center',
        column.justify === 'end' ? 'justify-end' : 'justify-start',
        className
      )}
      key={column.key}
    >
      {column.sortable && 
        <IconToggle
          active={filters.sortBy === column.key}
          onClick={() => {
            store.set.filter('sortBy', column.key);
            if (filters.sortBy === column.key) {
              store.set.filter('sortAsc', !filters.sortAsc);
            }
          }}
          className={clsx('!size-4 relative', filters.sortBy === column.key)}
        >
          <BsChevronDown className={clsx('transition-all', filters.sortBy === column.key ? 'text-text-accent' : 'text-white/40', filters.sortAsc ? 'rotate-0' : 'rotate-180')} />
        </IconToggle>
      }
      {children ?? column.label}
    </div>
  );
};

export const DataGrid = ({
  store,
  className,
  innerClassName,
  children,
  quickFilters=null,
  filterBar=null,
  headers=null,
  ...props
}: DataGridProps & HTMLAttributes<HTMLDivElement>) => {
  const {enableStarring, enableTrending} = store.use.config();
  const columns = store.use.columns();
  
  const isLG = useTailwindLG();
  const isMD = useTailwindMD();
  const isSM = useTailwindSM();

  const tableStyle = useMemo<CSSProperties>(() => ({
    gridTemplateRows: 'auto',
    gridAutoRows: '3rem',
    gridTemplateColumns: [
      enableStarring && isSM ? '[star] 40px' : '',
      ...columns.map((col) => col.mobile || (col.tablet && isMD) || isLG ? `[${col.key}] ${col.width ?? 'minmax(auto, 1fr)'}` : '')
    ].join(' ')
  }), [columns, isLG, isSM, isMD]);

  const filterStarred = store.use.filter('starred') as boolean;
  const filterTrending = store.use.filter('trending') as boolean;
  return (
    <storeContext.Provider value={store}>
      <div {...props} className={clsx('rounded-lg min-h-fit flex flex-col items-stretch justify-start !text-white w-full ', className)}>
        <div className='flex flex-row gap-2 py-2 px-0'>
          {(enableStarring || enableTrending || quickFilters) &&(
            <div className='rounded-xl bg-background3 flex gap-2 flex-row py-1 px-2'>
              {enableStarring && <IconToggle className="rounded-lg bg-background4" active={filterStarred} onClick={() => store.set.filter('starred', !store.get.filter('starred'))}><BsStarFill className={filterStarred ? 'text-accent-btn' : 'text-white/40'}/></IconToggle> }
              {enableTrending && <IconToggle className="rounded-lg bg-background4" active={filterTrending} onClick={() => store.set.filter('trending', !store.get.filter('trending'))}>
                <div style={{mask:'url(/assets/svg/trending.svg)', maskRepeat: 'no-repeat', maskPosition: 'center'}} className={clsx('size-3', filterTrending ? 'bg-white' : 'bg-white/40')}/>
              </IconToggle>}
              {quickFilters}
            </div>
          )}
          {filterBar}
          {/* {enableGridSwitching && (
          <div className='rounded-md bg-background3 flex flex-row ml-auto p-0'>
            <IconToggle className="rounded-none rounded-l-md" active={!gridMode} onClick={() => store.set.gridMode(!gridMode)}><BsListUl/></IconToggle> 
            <IconToggle className="rounded-none rounded-r-md" active={gridMode} onClick={() => store.set.gridMode(!gridMode)}><BsGridFill/></IconToggle> 
          </div>
        )} */}
        </div>
      
        <div className={clsx(
          'relative grid bg-background3 rounded-2xl pb-4',
          'overflow-x-auto gap-y-[1px] gap-x-2',
          'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white/20', innerClassName
        )} style={headers === null ? tableStyle : undefined}>
          <div className={clsx('grid grid-cols-subgrid col-span-full rounded-tl-2xl bg-background3 sticky top-0 z-[3]')} >
            {enableStarring && <div className='hidden sm:block'/>}
            {headers ?? columns.map((col) => (
              <HeaderCell key={col.key} column={col} />
            ))}
          </div>
          {children}
        </div>
      </div>
    </storeContext.Provider>
  );
};

type DataItemProps = {
  hide?: boolean;
  itemKey: string | number;
  tableItem: Record<string, ReactNode>,
  gridItem: ReactNode,
  tableClassName?: string,
  gridClassName?: string,
  onClick?: () => void,
  index?: number,
}

export const DataItem: FC<DataItemProps> = ({
  hide = false,
  itemKey,
  tableItem,
  tableClassName,
  onClick,
  index,
}) => {
  const store = useGridStore();
  //  const gridMode = store.use.gridMode();
  const {enableStarring} = store.use.config();
  const starred = store.use.starred();
  const filterByStar = store.use.filter('starred');
  const handleClick = () => {
    if (getSelection()?.toString()?.length === 0) {
      onClick?.();
    }
  };
  const columns = store.use.columnsMap();
  return (
    <Transition
      appear show={!(hide || (filterByStar && !starred[itemKey]))}
      enter="transform transition duration-200"
      enterFrom="opacity-0 scale-y-75"
      enterTo="opacity-100 scale-y-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 scale-y-100"
      leaveTo="opacity-0 scale-y-75"
    >
      <div
        style={{
          transitionDelay: `${200 * (index ?? 0)}ms`,
        }}
        onClick={handleClick}
        className={clsx(
          'grid grid-cols-subgrid col-span-full bg-background2 place-items-stretch transition-all relative',
          onClick && 'cursor-pointer hover:bg-gradient-to-r from-accent-btn/70 to-background2',
          tableClassName
        )}
      >
        {enableStarring && (
          <BsStarFill 
            style={{ gridColumn: 'star'}}
            className={clsx(
              'hidden sm:block',
              'place-self-center cursor-pointer',
              !!itemKey && starred[itemKey] ? 'text-accent-btn' : 'text-white/40'
            )}
            onClick={(e) => {
              e.stopPropagation();
              store.set.starred({ ... starred, [itemKey]: !starred[itemKey] });
            }}
          />
        )}
        {map(tableItem, (item, key) => 
          <div 
            key={key}
            style={{gridColumn: key}}
            className={clsx(
              columns[key].mobile ? 'contents' : 'hidden lg:contents',
              columns[key].tablet ? 'md:contents' : '',
            )}
          >
            {item}
          </div>
        )}
      </div>
    </Transition>
  );
};

export const LoadingRow = forwardRef<HTMLInputElement>((props, ref) => <div className='center h-12 col-span-full bg-background2 animate-pulse' {...props} ref={ref} />);
LoadingRow.displayName = 'LoadingRow';
 
export const PaginationObserver: FC<{hasNext: boolean; addPage: () => void}> = ({ addPage, hasNext }) => {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });
  useEffect(() => {
    if (entry?.isIntersecting) {
      addPage();
    }
  }, [entry]);
  return hasNext ? <LoadingRow ref={ref} /> : null;
};

export const EmptyHeader = <div className='w-full center pt-4'><h2>Empty</h2></div>;