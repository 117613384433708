export const NATIVE_TOKEN_DECIMALS = 6;
export const LP_TOKEN_DECIMALS = 6;
export const COMMISSION_RATE = 0.02; // 2%

export const classicNetwork = {
  name: 'classic',
  chainID: 'columbus-5',
  // lcd: "https://columbus-lcd.terra.dev", TFL
  // fcd: "https://columbus-fcd.terra.dev", TFL
  //  lcd: "https://terra-classic-lcd.publicnode.com", allnodes
  //  fcd: "https://terra-classic-fcd.publicnode.com", allnodes
  lcd: 'https://terra-classic-fcd.publicnode.com',
  fcd: 'https://terra-classic-fcd.publicnode.com',
  explorer: 'https://finder.terraport.finance/mainnet/',
  id: 'columbus-5',
  gasPrices: { uusd: 0.15 },
  fee: { gasPrice: 0.15, amount: 100000 }, // 0.1 USTC
  factoryContract: 'terra1n75fgfc8clsssrm2k0fswgtzsvstdaah7la6sfu96szdu22xta0q57rqqr',
  routerContract: 'terra1vrqd7fkchyc7wjumn8fxly88z7kath4djjls3yc5th5g76f3543salu48s',
  vestingContract: 'terra19v3vkpxsxeach4tpdklxaxc9wuwx65jqfs6jzm5cu5yz457hhgmsp4a48n',
  tokenContract: 'terra1ex0hjv3wurhj4wgup4jzlzaqj4av6xqd8le4etml7rg9rs207y4s8cdvrp',
  stakingContract: 'terra134ummlrj2rnv8h8rjhs6a54fng0xlg8wk7a2gwu6vj42pznkf6xs95966d',
  vestingContractInvestors: 'terra1y9nyupwjcmjw3t9lr8yvqkzt0gzt4hr2m0nk29ap2amy3qyz5njsgqqjw5',
  governanceContract: 'terra1m67k3ak5d05k7tzukstkujxptw0tnwt8cmf5kcxm6vwd403l4teshm07ls',
  burnContract: 'terra1rd8f2mhdhgtsfk5afcpfj78wzkljc4lns6t2rhwftvtwzdz95cuqmt7rmm',
  allowedPairContracts: [],
};

export const terraportNetworks = {
  classic: classicNetwork,
};
