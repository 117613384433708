import clsx from 'clsx/lite';
import { ComponentProps, FC, MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';

// TODO feature loading per evitare che l'utente posso spammare sui bottone
// serve capire come mostrarlo graficamente

type ButtonProps = ComponentProps<'button'> & { withCooldown?: boolean};

export const Button: FC<ButtonProps> = ({ className, withCooldown, ...props}) => {
  const [isInCooldown, setIsInCooldown] = useState(false);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    props.onClick?.(e);
    if(withCooldown){
      setIsInCooldown(true);
      setTimeout(() => {
        setIsInCooldown(false);
      }, 5000);
    }
  };

  return(
    <button
      {...props}
      className={clsx(
        'transition-all bg-accent2 hover:bg-accent-btn focus:bg-accent-btn/80 focus:shadow-accent-btn rounded-full px-4 py-2 font-semibold text-text-accent disabled:bg-accent-btn/40', 
        className
      )}
      onClick={handleClick}
      disabled={props.disabled || isInCooldown}
    />
  );
};

export const IconButton: FC<ComponentProps<'button'>> = ({ className, ...props}) => (
  <button
    {...props}
    className={clsx(
      'transition-all bg-accent2 hover:bg-accent-btn focus:bg-accent-btn/80 focus:shadow-accent-btn rounded-full size-6 flex items-center justify-center font-semibold text-text-accent disabled:bg-accent-btn/40', 
      className
    )}
  />
);

export const IconToggle: FC<ComponentProps<'button'> & { active: boolean }> = ({ className, active, ...props}) => (
  <button
    {...props}
    className={clsx(
      'transition-all rounded-full size-6 flex items-center justify-center font-semibold text-white', 
      active && ' bg-accent2 hover:bg-accent-btn focus:bg-accent-btn/80 focus:shadow-accent-btn disabled:bg-accent-btn/40 !text-text-accent ',
      className
    )}
  />
);

export const LinkButton: FC<ComponentProps<typeof Link>> = ({ className, ...props}) => (
  <Link
    {...props}
    className={clsx(
      'transition-all bg-accent2 hover:bg-accent-btn focus:bg-accent-btn/80 focus:shadow-accent-btn rounded-full px-4 py-2 font-semibold text-text-accent no-underline disabled:bg-accent-btn/40', 
      className
    )}
  />
);

export const MiniButton: FC<ComponentProps<'button'>> = ({ className, ...props }) => 
  <button className={clsx(className, 'outline-none px-2 rounded-full text-xs h-5 text-white bg-black/40')} {...props} />;

export const NotAButton: FC<ComponentProps<'div'>> = ({className, ...props}) => <div className={clsx('bg-black/40 rounded-full px-4 py-2 text-background1 font-bold center gap-2', className)} {...props}/>;
