import { Transition } from '@headlessui/react';
import { Fragment } from 'react/jsx-runtime';
import { Link, useLocation } from 'react-router-dom';
import { ComponentProps, FC } from 'react';
import { MultiNavItem, NavItem } from 'types/routes';
import clsx from 'clsx/lite';
import { Logo } from 'ui/components/logo';
import { HeaderButtons } from './header-buttons';
import { TrendingPairs } from 'ui/components/trending';
import { useAppRoutes } from 'hooks/use-routes';

type NavLinkProps = {
  route: NavItem | MultiNavItem;
  index?: number;
  linkClassName?: string;
  as?: ComponentProps<typeof Transition>['as'];
  current?: boolean;
};

const NavLink = ({ route, index = 0, linkClassName, as = 'div', current }: NavLinkProps) => {
  if(route.hidden?.()){
    return null;
  }
  return (
    // <Transition
    //   show
    //   style={{transitionDelay: `${index * 100}ms`}}
    //   enter="transition ease-out duration-100"
    //   enterFrom="transform opacity-0 scale-95"
    //   enterTo="transform opacity-100 scale-100"
    //   leave="transition ease-in duration-75"
    //   leaveFrom="transform opacity-100 scale-100"
    //   leaveTo="transform opacity-0 scale-95"
    //   as={as}
    // >
    <Link
      to={route.multipath ? route.paths[0]?.pathname : route.pathname}
      className={clsx(
        'transition-all',
        'text-white no-underline px-4 py-1',
        current && 'rounded-full bg-accent-btn !text-text-accent font-semibold', 
        current && route.multipath && ' bg-accent2',
        linkClassName
      )}
      target={route.openOtherPage ? '_blank' : '_self'}
    >
      {route.title}
    </Link>
    // </Transition>
  );
};

export const Header: FC = () => {
  const { pathname } = useLocation();
  const { routes, currentIndex } = useAppRoutes();
  return (<>
    <Transition
      show
      as={Fragment}
      appear
      enter="transition-all ease-in duration-200"
      enterFrom='-translate-y-full'
      enterTo='translate-y-0'
    >
      <header 
        className='flex sticky top-0 flex-row flex-wrap items-center justify-between bg-background3 w-dvw z-10 '
      >
        <div className="!text-white flex gap-4 items-center p-4 w-full justify-between px-8 pb-4 pt-8 h-fit flex-wrap">
          <Logo className='mr-auto' />
          <nav className='lg:flex hidden flex-row gap-2 mx-auto'>
            {
              routes.map((route, index) => (
                <NavLink 
                  key={route.title}
                  route={route}
                  index={index}
                  current={currentIndex === index} 
                />
              ))
            }
          </nav>
          <HeaderButtons/>
        </div>
        <Transition
          show={currentIndex >= 0 && !!routes[currentIndex]?.multipath}
          appear
          as="div"
          className="hidden lg:flex w-full bg-background1 gap-2 items-center justify-center mt-0 py-3"
          enter="transition-all ease-in duration-100"
          enterFrom='-translate-y-full'
          enterTo='translate-y-0'
          leave='transition-all ease-in duration-100'
          leaveTo='-translate-y-full opacity-0'
          leaveFrom='translate-y-0 opacity-100'
        >
          {currentIndex >= 0 && routes[currentIndex]?.multipath && (routes[currentIndex] as MultiNavItem).paths.map((route, index) => (
            <NavLink
              index={index}
              key={route.title}
              route={route}
              current={pathname.startsWith(route.pathname)}
              linkClassName='text-sm'
            />
          ))}
        </Transition>
        {(pathname === '/' || pathname.startsWith('/trade') || pathname.startsWith('/swap')) && <TrendingPairs/>}
      </header>
    </Transition>
  </>
  );
};
