import { FC, ReactNode } from 'react';
import { Footer, MobileNavMenu } from './footer';
import { Header } from './header';
import { ParallaxBackground } from 'ui/components/planet';

export const MainLayout: FC<{children: ReactNode}> = ({ children }) => (
  <div 
    id="layout"
    className='flex flex-col h-dvh items-center justify-start overflow-y-auto overflow-x-hidden bg-background4 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white/20'
  >
    <ParallaxBackground/>
    <Header/>
    {children}
    <Footer/>
    <MobileNavMenu/>
  </div>
);
