import { FC } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
    title: string;
    description: string;
    image: string;
}

export const HeaderMeta : FC<Props> = ({title, description, image}) => {
  return (
    <Helmet>
      {title && <meta property="og:title" content={title}/>}
      {title && <meta name="twitter:title" content={title}/>}
      {description && <meta name="description" content={description}/>}
      {description && <meta property="og:description" content={description}/>}
      {description && <meta name="twitter:description" content={description}/>}
      {image && <meta property="og:image" content={image}/>}
      {image && <meta name="twitter:image" content={image}/>}
    </Helmet>);
};