import { ComponentProps, FC, Fragment, ReactNode } from 'react';
import clsx from 'clsx/lite';
import { Transition } from '@headlessui/react';

export const Card: FC<Omit<ComponentProps<'section'>, 'title'> & {
  title: ReactNode;
  subtitle?: ReactNode;
  contentClassName?: string;
  subtitleClassName?: string;

  titleClassName?: string;
  isLoading?: boolean;
}> = ({children, title, subtitle = null, className, contentClassName, subtitleClassName, isLoading = false, titleClassName, ...sectionProps}) => (
  <Transition
    as={Fragment}
    appear show
    enter="transform transition duration-300"
    enterFrom="opacity-0 scale-80"
    enterTo="opacity-100 scale-100"
    leave="transform duration-200 transition ease-in-out"
    leaveFrom="opacity-100 scale-100 "
    leaveTo="opacity-0 scale-95 "
  >
    <section className={clsx('rounded-3xl bg-background3 text-white flex flex-col items-stretch justify-start relative', className)}
      {...sectionProps}>
      <div className='min-w-0 flex flex-row items-stretch justify-start rounded-t-3xl overflow-hidden'>
        <div className={clsx('min-w-0 py-3 pl-6 bg-gradient-to-r from-gr1 to-gr2 rounded-tl-3xl font-bold text-lg content-center relative uppercase', titleClassName)}>
          {title}
          <div className='absolute w-[100px] bg-gr2 -right-[99px] top-0 bottom-0' style={{ clipPath: 'url(#card-end)'}}/>
        </div>
        {subtitle && <div className={clsx('min-h-fit p-2 flex items-center justify-center ml-auto content-center z-2 absolute right-1 top-1', subtitleClassName)}>{subtitle}</div>}
      </div>
      <div className={clsx(isLoading && 'animate-pulse',contentClassName)}>{children}</div>
    </section>
  </Transition>
);