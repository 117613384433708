
import { Link } from 'react-router-dom';
import clsx from 'clsx/lite';
const TerraLogo = '/assets/img/terra.png';

export const Logo = ({ small = false, className }: {small?: boolean, className?:string}) => (
  <Link to={'/'} className={clsx('items-center text-accent1 no-underline flex gap-2', className)}>
    <img src={TerraLogo} width={small ? 24 : 32} height={small ? 24 : 32} alt="Terra Logo" />
    <p className={clsx(small && 'text-small', 'font-logo md:block hidden')}>TERRAPORT</p>
  </Link>
);