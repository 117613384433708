import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { baseUrl } from './base';
import { walletState } from 'state/wallet-state';
import { broadcastTx } from 'state/contract';
import { MsgExecuteContract } from 'cosmes/client';
import { Operation } from 'state/paid-ops';

export type Product = {
  id: number;
  name: string;
  price_asset: string;
  price_cents_usd: string;
  seller_address: string;
  info: {
    description: string;
  }
}

export type Products = Product[];

export const useGetProducts = () => useQuery({
  queryKey: ['products'],
  queryFn: async () => {
    const {data} = await axios.get<Products>(`${baseUrl}/products`, {
      headers: {
        Authorization: `Bearer ${walletState.get.jwt()}`
      }
    });
    return data;
  },
  refetchInterval: 30000
});

export type OrderInfo = {
  order: {
    buyler_address: string;
    seller_address: string;
    status: string;
    created_at: string;
    price_cents_usd: string;
    price: number;
    price_asset: string;
    order_items: [
      {
        product: string;
      }
    ],
    hash: string;
    tx_hash: null,
    paid_at: null,
    failed_at: null,
    id: number;
  }
  payment: {
    memo: string;
    contract: string;
    sender: string;
    msg: {
      transfer: {
        amount: string;
        recipient: string;
      }
    }
  }
};

export const orderProducts = async (products: number[]) => await axios.post<OrderInfo>(`${baseUrl}/order`, {
  product_ids: products
}, {
  headers: {
    Authorization: `Bearer ${walletState.get.jwt()}`
  }
});

export type OrderConfirmation = {
  buyler_address: string;
  seller_address: string;
  tx_hash: string;
  status: string;
  created_at: string;
  paid_at: string;
  failed_at: null,
  price_cents_usd: string;
  price: string;
  price_asset: string;
  hash: string;
  order_items:{ product: string; }[]
}

export const confirmOrder = async (order: string, hash: string) => await axios.post<OrderConfirmation>(`${baseUrl}/order/${order}/confirm`, {
  tx_hash: hash
}, {
  headers: {
    Authorization: `Bearer ${walletState.get.jwt()}`
  }
});

export const postOrderError = async (id: string, op: Operation, error: string) => await axios.post<OrderConfirmation>(`${baseUrl}/order/${id}/fail`, {
  operation: op,
  error
}, {
  headers: {
    Authorization: `Bearer ${walletState.get.jwt()}`
  }
});


export const paidTransaction = async (products: number[]): Promise<boolean> => {
  const { data: orderInfo} = await orderProducts(products);
  const { memo, ...msg } = orderInfo.payment;
  const tx = await broadcastTx([new MsgExecuteContract({ ...msg, funds: [] })], [], memo);
  if (tx?.txResponse.txhash) {
    const { data: response } = await confirmOrder(orderInfo.order.hash, tx.txResponse.txhash);
    if (response.status === 'PAID') {
      return true;
    }
  }
  return false;
};