import { baseUrl } from 'api/terraport/base';
import axios from 'axios';
import { find } from 'lodash';
import { Asset, PairInfo, TradePair } from 'types/pairs';
import { createStore } from 'zustand-x';

export type PairState = {
  pair: PairInfo | null;
  tradePair: TradePair | null;
  reversed: boolean;
  fromAsset: Asset | null;
  toAsset: Asset | null;
  degen: boolean;
}

export const pairState = createStore('pairs')<PairState>({
  degen: false,
  reversed: false,
  pair: null,
  tradePair: null,
  fromAsset: null,
  toAsset: null
}).extendSelectors((state) => ({
  pairFromAsset: () => state.pair?.first_token ?? null,
  pairToAsset: () => state.pair?.second_token ?? null,
})).extendActions((set, get) => ({
  toggleReverse: () => {
    const fromAsset = get.fromAsset();
    const toAsset = get.toAsset();
    const reversed = get.reversed();
    set.fromAsset(toAsset);
    set.toAsset(fromAsset);
    set.reversed(!reversed);
  },
})).extendActions((set, get) => ({
  reset: async ({fromAddr, toAddr}: {fromAddr?: string, toAddr?: string}) => {
    const {data} = await axios.get<{ exists: boolean; pair: PairInfo | null;}>(`${baseUrl}/pairs/exists?token0=${fromAddr ?? get.fromAsset()?.contract_addr}&token1=${toAddr ?? get.toAsset()?.contract_addr}`);
    if (data.exists && data.pair) {
      set.pair(data.pair);
      set.reversed(data.pair.second_token.contract_addr === fromAddr);
      set.fromAsset(find([data.pair.first_token, data.pair.second_token], a => a.contract_addr === fromAddr)?? null);
      set.toAsset(find([data.pair.first_token, data.pair.second_token], a => a.contract_addr === toAddr)?? null);
    }
  },
}));
