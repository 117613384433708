import { FC, Fragment, useState } from 'react';
import { Dialog, DialogPanel, Transition } from '@headlessui/react';
import { Button } from 'ui/components/button';
import { openWalletConnectModal, walletState } from 'state/wallet-state';
import { FaCheckCircle } from 'react-icons/fa';
import clsx from 'clsx/lite';
import { SearchBar } from 'ui/components/search-bar';

export const ConnectWalletButton = ({ label = 'WALLET' }: {label?: string}) => (
  <Button className="bg-gradient-to-t from-gr1 via-gr2 to-gr2 !text-white center gap-2" onClick={openWalletConnectModal}>{walletState.use.address() && <FaCheckCircle className=' text-theme-green' />}{label}</Button>
);

export const BuyCrypto: FC<{className?: string, label?: string}> = ({className, label='BUY CRYPTO'}) => {
  const [buy, setBuy] = useState(false);
  return (
    <>
      <Button id='btn-buy-crypto' onClick={() => setBuy(true)} className={clsx('text-nowrap', className)}>
        {label}
      </Button>
      <Transition
        appear
        show={buy}
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog as="div" className="relative z-10" onClose={() => setBuy(false)}>
          <div className="fixed inset-0 bg-black/50 backdrop-blur-md" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-dvh items-center justify-center text-center">
              <DialogPanel className="transition-all">
                <iframe
                  className="border-none min-h-[334px] max-w-[450px] rounded-[2rem]"
                  width="450"
                  title="Ramp Widget"
                  src="https://guardarian.com/calculator/v1?partner_api_token=5aec0db8-5cef-4d90-a2c0-023d6a316c14&theme=blue&type=narrow&default_fiat_amount=50&crypto_currencies_list=%5B%7B%22ticker%22%3A%22LUNC%22%2C%22network%22%3A%22LUNC%22%7D%5D&fiat_currencies_list=%5B%7B%22ticker%22%3A%22EUR%22%2C%22network%22%3A%22EUR%22%7D%2C%7B%22ticker%22%3A%22USD%22%2C%22network%22%3A%22USD%22%7D%2C%7B%22ticker%22%3A%22GBP%22%2C%22network%22%3A%22GBP%22%7D%2C%7B%22ticker%22%3A%22TRY%22%2C%22network%22%3A%22TRY%22%7D%5D"
                />
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export const HeaderButtons: FC = () => {

  return (
    <div className="flex gap-6 ml-auto">
      <SearchBar/>
      <BuyCrypto className="hidden lg:block" />
      <ConnectWalletButton/>
    </div>
  );
};