import { ComponentProps, FC } from 'react';
import { Tooltip as TT } from 'react-tooltip';

export const Tooltip: FC<ComponentProps<typeof TT>> = ({style = {}, ...props}) => <TT 
  style={
    { 
      ...style,
      color: '#9A832F',
      backgroundColor: '#FFE6A4',
      boxShadow: '0px 0px 4px 0px #FFE6A4',
      borderRadius: '1rem', 
      zIndex: '50',
    }}
  {...props}
  className='!opacity-100'
/>;