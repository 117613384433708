import { Routes, Route } from 'react-router';
import { HeaderMeta } from 'ui/components/header-meta';

export const HeaderMetas = () => {
  return (
    <Routes location={location}>
      <Route path="/trade" element={
        <HeaderMeta
          title='Terraport Trade'
          description='Terraport is an autonomous DeFi platform using smart contracts on the Terra Classic blockchain.'
          image={`${window.location.origin}/assets/pwa/PagePreview_Trade.webp`}
        />
      } />
      <Route path="/trade/swap" element={null} />
      <Route path='/launchpad' element={
        <HeaderMeta
          title='Terraport Launchpad'
          description='Terraport is an autonomous DeFi platform using smart contracts on the Terra Classic blockchain.'
          image={`${window.location.origin}/assets/pwa/PagePreview_Launchpad.webp`}
        />
      } /> 
      <Route path="*" element={
        <HeaderMeta
          title='Terraport | Classic'
          description='Terraport is an autonomous DeFi platform using smart contracts on the Terra Classic blockchain.'
          image={`${window.location.origin}/assets/pwa/PagePreview_Banner.webp`}
        />
      }/>
    </Routes>
  );
};