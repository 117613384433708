import { FC, Suspense } from 'react';
import { CardModal } from '../components/modal';
import { Button, IconButton } from '../components/button';
import { closeWalletConnectModal, useAddress, walletState } from '../../state/wallet-state';
import { WalletType } from 'cosmes/wallet';
import { DEFAULT_CHAIN } from '../../consts/misc';
import { FaX } from 'react-icons/fa6';
import { capitalize, round } from 'lodash';
import { Planet, Star } from 'ui/components/planet';
import { BsCopy } from 'react-icons/bs';
import { copyToClipboard, getShortAddress } from 'Helpers/utils';
import { useTerraBalance } from 'api/rpc/balance';
import { useGetTradePairsQuery } from 'api/terraport/pairs';
import { FormattedNumber } from 'ui/components/formatted-number';
import { BuyCrypto } from 'ui/layout/header-buttons';
import Pending from 'ui/layout/pending';
import { useQuests } from 'api/terraport/quests';
import { Tooltip } from 'ui/components/tooltip';

const CurrentWallet = () => {
  const address = useAddress();
  const wallet = walletState.use.wallet();
  const controller = walletState.use.currentController();
  const balance = useTerraBalance();
  const pairs = useGetTradePairsQuery();
  const {data: questsData} = useQuests(address);

  const terraPriceDollars = pairs.data?.find(pair => pair.second_asset.microdenom === 'TERRA')?.second_asset?.price_dollars;

  if (!wallet) return null;

  return <div className='flex flex-col gap-4 items-start w-full' style={{ zIndex: 1 }}>
    <div className='flex flex-col gap-2 sm:gap-4 items-center justify-between min-w-0 w-full'>

      <div className='overflow-clip sm:mt-10 size-[100px] sm:size-[160px] relative rounded-2xl' >
        <img className="absolute sm:-left-[128px] sm:top-0 -left-[84px] top-0 sm:w-[300px] w-[200px]" src='assets/img/image 17.png'></img>
      </div>

      <div className='flex flex-row items-center justify-between gap-2'>
        <div className="text-xl text-ellipsis hover:underline hover:underline-offset-4 cursor-pointer" onClick={()=>window.open(`https://finder.terraport.finance/mainnet/address/${wallet.address}`, '_blank')}>
          {getShortAddress(wallet.address)}
        </div>
        <IconButton className='w-[24px]' onClick={() => copyToClipboard(wallet.address)}>
          <BsCopy className="" />
        </IconButton>
      </div>

      <Button
        className='' 
        style={{ width: 160 }} 
        onClick={() => controller?.disconnect([DEFAULT_CHAIN])}
      >
        LOG OUT
      </Button>

    </div>
    <div className='flex  justify-between w-full md:mt-[20px] flex-col sm:flex-row'>
      <div className="flex flex-col justify-start items-start" style={{ flexGrow: 1 }}>
        <BuyCrypto />
        <p className='font-bold pt-2'>Balance:</p>
        <FormattedNumber value={balance * Number(terraPriceDollars) ?? 0} suffix="$" className='mt-2' />
        <p className='font-bold sm:text-3xl text-xl' style={{ color: '#b0e5fd' }}>
          <FormattedNumber value={balance} className='font-bold mt-1' suffix="TERRA"/>
        </p>
        <br></br>
        <p id="points-info" className='font-bold'> Points: {round(Number(questsData?.quest_rewards ?? '0') + (questsData?.leaderboard_score ?? 0))}</p>
        <Tooltip anchorSelect='#points-info' >you can get points by daily activities and swapping </Tooltip>
      </div>
      {controller && <div className="flex flex-col justify-start items-start mt-5 sm:mt-0">
        <p className='font-bold'>Wallet:</p>
        <div className='flex flex-row mt-3 flex-wrap'>
          <img className="rounded-2xl mb-2 mr-4 size-12 sm:size-16" src={`/assets/img/wallets/${controller.id}.webp`} />
          <div className='flex flex-col justify-start items-start'>
            <p className='text-sm'>via {capitalize(wallet.id)} {wallet.type === WalletType.WALLETCONNECT ? 'Mobile' : 'Extension'}</p>
            <div className='flex flex-row items-center justify-between mt-2' style={{ width: 150 }}>
              <span className="text-sm text-nowrap overflow-hidden text-ellipsis min-w-0">{wallet.address.slice(0, -4)}</span>
              <span className="text-sm">{wallet.address.slice(-4)}</span>
              <IconButton className=' w-20 z-30 ml-2' onClick={() => copyToClipboard(wallet.address)}>
                <BsCopy className="" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>}
    </div>

    {/* {buy && <Dialog as="div" className="relative z-10" onClose={() => setBuy(false)}>
      <div className="fixed inset-0 bg-black/50" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-dvh items-center justify-center text-center">
          <FaX className="cursor-pointer p-2 self-start" onClick={() => setBuy(false)} />
          <Dialog.Panel className="transition-all">
            <iframe
              className="border-none"
              height="660"
              width="660"
              title="Ramp Widget"
              src="https://guardarian.com/calculator/v1?partner_api_token=5aec0db8-5cef-4d90-a2c0-023d6a316c14&theme=blue&type=narrow&default_fiat_amount=50&crypto_currencies_list=%5B%7B%22ticker%22%3A%22LUNC%22%2C%22network%22%3A%22LUNC%22%7D%5D&fiat_currencies_list=%5B%7B%22ticker%22%3A%22EUR%22%2C%22network%22%3A%22EUR%22%7D%2C%7B%22ticker%22%3A%22USD%22%2C%22network%22%3A%22USD%22%7D%2C%7B%22ticker%22%3A%22GBP%22%2C%22network%22%3A%22GBP%22%7D%2C%7B%22ticker%22%3A%22TRY%22%2C%22network%22%3A%22TRY%22%7D%5D"
            />
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>} */}


  </div>;

};

export const ConnectedWalletModal: FC = () => {
  const open = walletState.use.connectModalIsOpen();

  return (
    <CardModal
      open={open}
      onClose={closeWalletConnectModal}
      className='sm:w-[700px] sm:max-w-[90vw] w-[90dvw] max-h-[95dvh] relative'
      title={
        <div className='min-w-0 flex flex-col items-start p-2'>
          <h1 className="text-start font-bold text-4xl">Profile</h1>
        </div>
      }
      subtitle={
        <FaX className="cursor-pointer p-2 self-start text-white size-8" onClick={closeWalletConnectModal} />
      }
    >
      <div className="relative p-4 sm:p-6 flex flex-col bg-[#0D1624]/50 items-center rounded-b-3xl bg-center bg-no-repeat" >
        <div className='bg-[#0D1624]' style={{
          position: 'absolute', top: -80, bottom: 0, left: 0, right: 0, opacity: 0.7, height: 80, borderTopLeftRadius: 20,
          zIndex: -1,
          borderTopRightRadius: 20
        }}></div>
        <div style={{ position: 'absolute', top: -80, bottom: 0, left: 0, right: 0, zIndex: -2, overflow: 'clip', borderRadius: '20px' }}>
          <img
            src="/assets/img/wallets/bg.webp"
            alt="Background"
            className="absolute object-cover -top-[10%] sm:-top-[30%] -left-[15%] w-[130%]"
          />
        </div>

        <Planet src="PlanetMagenta01_RadialShadow_1024" top="0px" left="-50px" className='hidden sm:block'/>
        <Planet src="PlanetYellow01_RadialShadow_1024" bottom="-40px" right="-90px" size="140px" className='hidden sm:block'/>
        <Star size='18px' right="8%" top="25%" />
        <Star size='8px' right="6%" top="8%" />
        <Star size='8px' left="10%" bottom="40%" />
        <Star size='6px' left="6%" top="10%" />
        <Suspense fallback={<Pending small/>}>
          <CurrentWallet />
        </Suspense>
      </div>
    </CardModal>
  );
};
