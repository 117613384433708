import axios from 'axios';

export type ChainInfo = {
  baseAsset: string;
  chainID: string;
  channels: { [id: string]: string };
  coinType: string;
  explorer: {
    address: string;
    tx: string;
    validator: string;
    block: string;
  };
  gasAdjustment: number;
  gasPrices: { [currency: string]: number };
  icon: string;
  lcd: string;
  name: string;
  prefix: string;
  isClassic?: boolean;
};

export type Chains = {
  [id: string]: ChainInfo;
};

export type TerraChains = {
  classic: Chains;
};

export const getTerraClassicChains = async () => {
  const res = await axios.get<TerraChains>('https://station-assets.terra.dev/chains.json');
  return res.data.classic;
};
