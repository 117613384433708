import { Transition } from '@headlessui/react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { baseUrl } from 'api/terraport/base';
import axios from 'axios';
import { millisecondsInMinute } from 'date-fns/constants';
import { map } from 'lodash';
import { FC } from 'react';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';

type TrendingResponse = {
  contract_address: string;
  position: number;
  first_asset: {
    contract_address: string,
    denom: string;
    img: string;
    microdenom: string;
  };
  second_asset: {
    contract_address: string,
    denom: string;
    img: string;
    microdenom: string;
  }
}[]

export const TrendingPairs: FC = () => {

  const { data } = useQuery<TrendingResponse>({
    queryKey: ['trending'],
    queryFn: async () => {
      const {data} = await axios.get(`${baseUrl}/trade-view-pair-data/trending`);
      return data;
    },
    refetchInterval: 5 * millisecondsInMinute
  });

  return (
    <Transition
      show appear
      as="div"
      className="w-full bg-[#522257] flex flex-row items-center justify-start mt-0 text-white"
      enter="transition-all ease-in duration-100 delay-300"
      enterFrom='-translate-y-full'
      enterTo='translate-y-0'
      leave='transition-all ease-in duration-100'
      leaveTo='-translate-y-full opacity-0'
      leaveFrom='translate-y-0 opacity-100'
    >
      <div className="relative flex py-2 pl-6 pr-1 gap-2 h-full bg-[#9E278C] font-bold text-lg justify-self-start items-center">
        <img src="/assets/svg/trending.svg" className="size-4"/>
        <p className='hidden md:center text-sm'>
          TRENDING
        </p>
        <div className='w-[40px] absolute -right-[38px] top-0 bottom-0 bg-[#9E278C] self-stretch z-[3]' style={{ clipPath: 'url(#card-end)'}}/>
      </div>
      <Marquee
        className='ml-6'
        gradient
        gradientWidth={150}
        gradientColor='#522257'
        speed={40}
        pauseOnHover
        play
      >
        {map(data, pair => (
          <Link
            to={{ pathname: '/trade/swap', search: `from=${pair.first_asset.contract_address}&to=${pair.second_asset.contract_address}`}}
            key={`${pair.first_asset.denom}${pair.second_asset.denom}`}
            className="no-underline text-white transition-all flex flex-row px-4 py-1 rounded-lg items-center hover:bg-[#9E278C] relative"
          >
            <p className='font-bold'>#{pair.position}</p>
            <img
              src={pair.first_asset.img}
              className="size-5 ml-2 mr-5 rounded-full"
            />
            <img
              src={pair.second_asset.img}
              className="size-5 mx-2 rounded-full absolute left-12"
            />
            <p>{pair.first_asset.denom}/</p>
            <p className="text-white/40">{pair.second_asset.denom}</p>
          </Link>
        ))
        }
      </Marquee>
    </Transition>
  );
};